import * as React from 'react'

const SimpleArrow = (props) => (
	<svg width={8} height={13} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
		<path
			d='M.294 5.296c-.39.39-.39 1.025 0 1.416l5 5a1.002 1.002 0 0 0 1.415-1.416L2.416 6.002l4.29-4.293A1.002 1.002 0 0 0 5.291.293l-5 5 .003.003Z'
			fill='var(--cream)'
		/>
	</svg>
)

export default SimpleArrow
