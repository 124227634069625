import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import styled from 'styled-components'

import BasicRoundedButton from '../../components/BasicRoundedButton/ButtonLink'
import { CardStyles } from '../../components/Card'
import SimpleArrow from '../../public/svg/simpleArrow'
import { Language } from '../../types/Types'
import { media } from '../../utils/style-utils'
import { ErrorPageDocument } from '../../../prismicio-types'

interface IProps {
	page: ErrorPageDocument
	locale: Language
}

const ErrorPage: React.FC<IProps> = ({ page }: IProps) => {
	const image = page.data.image
	const { back } = useRouter()

	return (
		<Container backgroundColor='var(--neon)'>
			<ImageContainer>
				{image && image.url && (
					<ErrorImage
						fill
						src={image.url}
						alt={image.alt ?? ''}
					/>
				)}
			</ImageContainer>
			<SubText>{page.data.subtext}</SubText>
			<Link
				href=''
				onClick={() => back()}
			>
				<Button>
					<ButtonIcon />
					<ButtonText>Fara til baka</ButtonText>
				</Button>
			</Link>
		</Container>
	)
}

const ButtonIcon = styled(SimpleArrow)`
	margin-right: 14px;

	${media.phone`
		margin-right: 12px;
	`}
`

const ButtonText = styled.span`
	margin-top: -5px;
`

const Button = styled(BasicRoundedButton)`
	color: white;

	display: flex;
	align-items: center;
`

const ErrorImage = styled(Image)`
	object-fit: contain;
	max-width: 100%;
	max-height: 100%;
`

const ImageContainer = styled.div`
	width: 100%;
	flex-grow: 1;
	height: 260px;

	margin-top: var(--spacer-base);

	/* max-height: 100px; */
	position: relative;

	${media.tablet`
		height: 300px;
	`}

	${media.phone`
		height: 150px;
	`}
`

const Container = styled.div`
	${CardStyles}

	flex-direction: column;
	margin: 0 40px;
	padding: 40px;
	aspect-ratio: unset;
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: center;

	${media.tablet`

		p {
			margin: 15px;
		}

		padding: 20px;
		margin: 10px 10px;
	`}
`

const SubText = styled.p`
	font-family: var(--font-atyp-display);
	font-style: normal;
	font-weight: 500;
	font-size: 32px;
	line-height: 150%;
`

export default ErrorPage
