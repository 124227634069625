import * as PrismicTypes from '@prismicio/types'
import React from 'react'
import styled from 'styled-components'

import ErrorPage from '../modules/ErrorPage'
import { Language } from '../types/Types'
import { getDocumentByUid, getLayoutData, getSingletonPageByType } from '../utils/prismic/data-helpers'
import { ErrorPageDocument } from '../../prismicio-types'

interface IProps {
	page: ErrorPageDocument
	previewData: PrismicTypes.Ref
	locale: Language
}

const Index: React.FC<IProps> = (props) => {
	return <ErrorPage {...props} />
}

export const getStaticProps = async ({ previewData, locale }: IProps) => {
	const { ref } = previewData || {}

	const [page, layoutData] = await Promise.all([
		getDocumentByUid('error_page', 'error-page-404', ref, locale),
		getLayoutData(ref, locale),
	])

	return {
		props: {
			page,
			layoutData: { ...layoutData },
		},
	}
}

export default Index
